import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";

const NavBar = () => {
    const [activeSection, setActiveSection] = useState('hero');
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);

            const sections = ['hero', 'features', 'tokenomics', 'roadmap', 'about'];
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            for (let section of sections) {
                const element = document.getElementById(section);
                if (element) {
                    const { top, bottom } = element.getBoundingClientRect();
                    if (top <= scrollPosition && bottom >= scrollPosition) {
                        setActiveSection(section);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            <header className={`header ${isScrolled ? 'header-scrolled' : ''}`}>
                <div className="header-content">
                    <nav className="navigation">
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2 }}
                            className="logo"
                        >
                            SenseiBot
                        </motion.div>

                        {/* Mobile Menu Toggle */}
                        <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <div className={`nav-links ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
                            {['how to buy', 'stake & earn', 'features', 'tokenomics', 'roadmap', 'aboutus'].map((section) => (
                                <a
                                    key={section}
                                    className={activeSection === section ? 'active' : ''}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setIsMobileMenuOpen(false);

                                        if (section === 'how to buy') {
                                            navigate('/howtobuy');
                                        } else if (section === 'stake & earn') {
                                            navigate('/staking');
                                        } else if (section === 'home') {
                                            navigate('/');
                                        } else {
                                            const element = document.getElementById(section);
                                            if (element) {
                                                element.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }
                                    }}
                                >
                                    {section === 'aboutus' ? 'About Us' : section.charAt(0).toUpperCase() + section.slice(1)}
                                </a>
                            ))}
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="connect-wallet"
                                onClick={() => {
                                    navigate('/buy/sell');
                                }}
                            >
                                Buy SBOT
                            </motion.button>
                        </div>

                    </nav>
                </div>
            </header>
        </div>
    );
};

export default NavBar;
