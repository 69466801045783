import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { motion, AnimatePresence } from "framer-motion";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import sample from "../../example/WhitePaper.pdf";
import SENSEIBOT from '../../assets/images/SENSEIBOT.png'
import sabot from "../../assets/images/bot-2.jpeg"
import sabot3 from "../../assets/images/bot-3.jpeg"
import sabot4 from "../../assets/images/bot-4.jpeg"
import sabot5 from "../../assets/images/bot-5.jpeg"
import { Link, useNavigate } from 'react-router-dom';
import "./index.css";
import BackToTop from "../../backtotop";
import NavBar from "../../common/nav2";



ChartJS.register(ArcElement, Tooltip, Legend);

const Updatedhome = () => {
    const [activeSection, setActiveSection] = useState('hero');
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    // Tokenomics data for Pie Chart

    const tokenomicsData = [
        { label: "Marketing", percentage: 5.0, color: '#3498db' },
        { label: "Reserve", percentage: 10.0, color: '#2ecc71' },
        { label: "Development", percentage: 10.0, color: '#e74c3c' },
        { label: "Team", percentage: 15.0, color: '#f39c12' },
        { label: "Liquidity", percentage: 10.0, color: '#9b59b6' },
        { label: "Initial Sale", percentage: 15.0, color: '#1abc9c' },
        { label: "Ecosystem Rewards", percentage: 40.0, color: '#34495e' },
    ];

    const pieData = {
        labels: tokenomicsData.map(item => item.label),
        datasets: [{
            data: tokenomicsData.map(item => item.percentage),
            backgroundColor: tokenomicsData.map(item => item.color),
            hoverBackgroundColor: tokenomicsData.map(item => item.color),
        }]
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${context.formattedValue}%`;
                    }
                }
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);

            const sections = ['hero', 'features', 'tokenomics', 'roadmap', 'about'];
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            for (let section of sections) {
                const element = document.getElementById(section);
                if (element) {
                    const { top, bottom } = element.getBoundingClientRect();
                    if (top <= scrollPosition && bottom >= scrollPosition) {
                        setActiveSection(section);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const pageVariants = {
        initial: { opacity: 0, y: 20 },
        in: { opacity: 1, y: 0 },
        out: { opacity: 0, y: -20 }
    };

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.5
    };


    return (
        <>
            <NavBar />
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="app-wrapper"
            >

                <main>

                    <section id="hero" className="hero-section">
                      


                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6 }}
                            className="hero-content"
                        >
                            <div className="img-div tex-light">
                                <img src={SENSEIBOT} className="img" alt="..." />
                            </div>
                            <h1>SenseiBot is a cryptocurrency powered by artificial intelligence</h1>
                            <p>Join an ecosystem where your investment grows with advanced automated trading technology.
                            </p>
                            <div className="hero-cta">
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="primary-btn"
                                    onClick={() => {
                                        navigate('/buy/sell');
                                    }}
                                >
                                    Buy SBOT
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="secondary-btn"
                                    onClick={() => window.open(sample, '_blank')}>

                                    white paper
                                </motion.button>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6 }}
                            className="hero-visual"
                        >
                            <div className="trading-interface">
                            </div>
                        </motion.div>
                    </section>

                    <section id="features" className="features" style={{ backgroundColor: "#0D1117" }}>
                        <h2 style={{ color: '#fff' }}>How to Get Started ?</h2>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={{
                                hidden: { opacity: 0 },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        staggerChildren: 0.2
                                    }
                                }
                            }}
                            className="features-grid"
                        >
                            {[
                                {
                                    icon: "🤖",
                                    title: " Register with the SenseiBot Ecosystem",
                                    description: "Create your account and make sure you have a compatible wallet to store your SBOT tokens. Built on the Binance Smart Chain, SBOT operates seamlessly on the Binance network."
                                },
                                {
                                    icon: "📊",
                                    title: "Purchase SenseiBot in the Presale",
                                    description: "Take advantage of exclusive presale prices and secure your tokens before the official launch."
                                },
                                {
                                    icon: "🔒",
                                    title: "Deposit Your Tokens into the Mining Pool",
                                    description: "Participate in our AI-powered mining system to earn SBOT rewards weekly."
                                },
                                {
                                    icon: "💸",
                                    title: "Withdraw or Reinvest Your Earnings",
                                    description: "Withdraw your rewards in BNB, or reinvest them into the pool to earn even more."
                                }
                            ].map((feature, index) => (
                                <motion.div
                                    key={index}
                                    className="feature-card"
                                    variants={{
                                        hidden: { opacity: 0, y: 50 },
                                        visible: {
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                type: "spring",
                                                damping: 12,
                                                stiffness: 100
                                            }
                                        }
                                    }}
                                >
                                    <div className="feature-icon">{feature.icon}</div>
                                    <h3>{feature.title}</h3>
                                    <p>{feature.description}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </section>

                    <section id="tokenomics" className="tokenomics" style={{ backgroundColor: "#0D1117" }}>
                        <div className="container">
                            <motion.h2
                                initial={{ opacity: 0, y: -50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                                className="section-title"
                                style={{ color: '#fff' }}
                            >
                            </motion.h2>
                                <h2 style={{ color: '#fff',justifyContent:'center',display:'flex',paddingBottom:'10px' }}>Tokenomics</h2>
                            <div className="tokenomics-content">
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                    className="tokenomics-chart"
                                >
                                    <Pie
                                        data={pieData}
                                        options={pieOptions}
                                    />
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, x: 50 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="tokenomics-details"
                                >
                                    {tokenomicsData.map((item, index) => (
                                        <div key={index} className="tokenomics-item">
                                            <div className="tokenomics-item-label">
                                                <span
                                                    className="color-indicator"
                                                    style={{ backgroundColor: item.color }}
                                                />
                                                <span>{item.label}</span>
                                            </div>
                                            <span className="tokenomics-item-percentage">{item.percentage}%</span>
                                        </div>
                                    ))}
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <section id="roadmap" className="roadmap" style={{ backgroundColor: "#0D1117" }}>
                        <h2 style={{ color: '#fff' }}>SenseiBot Roadmap</h2>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={{
                                hidden: { opacity: 0 },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        staggerChildren: 0.3
                                    }
                                }
                            }}
                            className="roadmap-timeline"
                        >
                            {[
                                {
                                    phase: "Phase 1: Preparation and Presale",
                                    date: "December 2024 - January 2025",
                                    description: `
          December 16, 2024: Start of the first stage of the presale from $0.005 per token. Tokens purchased during the pre-sale will be released five days after the official launch.
          December 30, 2024: Start of the second stage of the presale from $0.009 per token.
        `
                                },
                                {
                                    phase: "Phase 2: Official Launch",
                                    date: "January 2025",
                                    description: `
          January 20, 2025: Official launch of SenseiBot. Release of presale tokens for use in the mining ecosystem. Continued development of the SBOT-based ecosystem.
        `
                                },
                                {
                                    phase: "Phase 3: Growth and Exchange Listings",
                                    date: "February 2025 - September 2025",
                                    description: `
          March - August 2025: Listing of SBOT on 3 to 5 selected exchanges. Token buybacks and burns funded by trading bot profits, increasing the token’s value and liquidity.
          May 2025: Introduction of new features to the SBOT ecosystem.
        `
                                },
                                {
                                    phase: "Phase 4: Ecosystem Expansion",
                                    date: "Second Half of 2025",
                                    description: `
          July - December 2025: Development of educational games on cryptocurrency topics, payable with SBOT. New strategic partnerships and expansion of the SenseiBot ecosystem.
        `
                                }
                            ].map((step, index) => (
                                <motion.div
                                    key={index}
                                    className={`roadmap-item ${index % 2 === 0 ? 'left' : 'right'}`}
                                    variants={{
                                        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
                                        visible: {
                                            opacity: 1,
                                            x: 0,
                                            transition: {
                                                type: "spring",
                                                damping: 12,
                                                stiffness: 100
                                            }
                                        }
                                    }}
                                >
                                    <div className="roadmap-marker"></div>
                                    <div className="roadmap-content">
                                        <h3>{step.phase}</h3>
                                        <span>{step.date}</span>
                                        <p>{step.description}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    </section>

                    <section id="aboutus" className="about-section">
                        <div className="container">
                            <div className="top-heading text-center">
                                <p className="heading-p">About SenseiBot</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                </div>
                            </div>
                            <div className="row align-item-center">
                                <div className="col-lg-7 content-center">
                                    <div className="left-about">
                                        <p className="about-p">Mission and Vision
                                        </p>
                                        <p className="about-p-1">Mission: To revolutionize participation in the cryptocurrency ecosystem by offering an
                                            AI-driven mining platform that enables users to benefit from automated trading and community growth.<br />
                                            Vision: To become a global leader in crypto mining and payments, facilitating access to and usage of
                                            cryptocurrencies in the everyday lives of our community.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="right-about">
                                        <img src={sabot} alt="" />
                                    </div>
                                </div>
                                <div />
                            </div>
                            <div className="row align-item-center order-class">
                                <div className="col-lg-7 content-center">
                                    <div className="right-about">
                                        <p className="about-p">Key Features of SenseiBot
                                        </p>
                                        <p className="about-p-1">AI-Powered Mining Based on Trading: A model where automated trading generates profitability
                                            that is reinvested into the ecosystem.<br />
                                            Advanced Techniques: Incorporates arbitrage, scalping, and day trading, combining technical
                                            and fundamental analysis with a success probability exceeding 70%.<br />
                                            Token Buybacks and Burns: Uses profits to buy back and burn SBOT, thereby increasing the token's value.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="left-about">
                                        <img src={sabot3} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-item-center">
                                <div className="col-lg-7 content-center">
                                    <div className="right-about">
                                        <p className="about-p">The Team: Who is Ryushi Ryu?
                                        </p>
                                        <p className="about-p-1">Ryushi is the pseudonym of a team of traders and programmers with over 5 years of experience.
                                            Their expertise in financial markets and blockchain technology supports the project's automated processes.


                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="left-about">
                                        <img src={sabot4} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-item-center order-class">
                                <div className="col-lg-7 content-center">
                                    <div className="right-about">
                                        <p className="about-p">Commitment to the Community
                                        </p>
                                        <p className="about-p-1">SBOT is designed to be a utility cryptocurrency that evolves with its community, fostering a growth-oriented
                                            and empowering ecosystem for users. <br /> Through incentives and a progressive participation model, we aim to
                                            build the future of SenseiBot together.


                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="left-about">
                                        <img src={sabot5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <BackToTop />
                </main>

                <footer className="footer">
                    <div className="footer-content">
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="footer-logo">SenseiBot</div>
                            {/* <div className="footer-links">

                                {['features', 'tokenomics', 'roadmap', 'aboutus'].map((section) => (
                                    <a key={section} href={`#${section}`}>
                                        {section === 'aboutus' ? 'About Us' : section.charAt(0).toUpperCase() + section.slice(1)}
                                    </a>
                                ))}
                            </div> */}
                            <div className="social-links">
                                <a href="https://twitter.com/xSenseibotx">Twitter</a>
                                <a href="https://t.me/SENSEIBOT_SBOT">Telegram</a>
                                <a href="https://www.tiktok.com/@xsenseibotx?_t=ZM-8stdeoqbt7W&_r=1">Tiktok</a>
                                <a href="https://www.instagram.com/xsenseibotx/">Instagram</a>
                            </div>
                            <p>© 2024 SenseiBot. All Rights Reserved.</p>
                        </motion.div>
                    </div>
                </footer>
            </motion.div>
        </>
    );
};

export default Updatedhome;