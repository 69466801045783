import "@rainbow-me/rainbowkit/styles.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiConfig } from "wagmi";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { http } from 'viem';

// Define BSC Mainnet and Testnet
const bscMainnet = {
  id: 56,
  name: "Binance Smart Chain",
  network: "bsc",
  nativeCurrency: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://bsc-dataseed.binance.org/"],
    },
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
  testnet: false,
};

const bscTestnet = {
  id: 97,
  name: "Binance Smart Chain Testnet",
  network: "bscTestnet",
  nativeCurrency: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    },
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" },
  },
  testnet: true,
};

// Create a root and query client
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

// Define wagmi config
const config = getDefaultConfig({
  appName: "RainbowKit Integration",
  projectId: "11b85d17c70f94a70c3c3c69b3a0b2f9",
  chains: [bscMainnet, bscTestnet],
  transports: {
    [bscMainnet.id]: http(),
    [bscTestnet.id]: http(),
  },
  ssr: false,
});

// Render the app
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider showRecentTransactions={true}>
            <App />
            <ToastContainer />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiConfig>
    </Provider>
  </React.StrictMode>
);

// Report web vitals
reportWebVitals();