import React, { useState, useEffect } from 'react';
import './index.css';
import { parseEther, formatEther } from 'viem';
import Navbar from '../../common/nav';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContractRead, useAccount, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import {
  sabotaddress,
  sabotaddressAbi,
  stakeContractAbi,
  stakeContractaddress,
} from '../../common/mainnet-abi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/loader';
import { useNavigate } from 'react-router-dom';
import AdminNav from '../../common/Adminnav';

const Staking = ({ isOwner }) => {
  const { address, isConnected } = useAccount();
  const [stakingAmount, setStakingAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [isApproving, setIsApproving] = useState(false);
  const [approveHash, setApproveHash] = useState(null);
  const [allowstake, setAllowstake] = useState(false);
  const [stakingMessage, setStakingMessage] = useState('');

  const getValue = localStorage.getItem("value") || "";

  const {
    data: hash,
    isPending,
    error: writeContractError,
    writeContract,
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
  } = useWaitForTransactionReceipt({ hash });

  const {
    isLoading: isApproveConfirming,
    isSuccess: isApproveConfirmed,
  } = useWaitForTransactionReceipt({ hash: approveHash });

  // Contract reads
  const { data: allowance = 0 } = useContractRead({
    address: sabotaddress,
    abi: sabotaddressAbi,
    functionName: 'allowance',
    args: [address, stakeContractaddress],
    watch: true,
    enabled: !!address,
  });

  const { data: balance = 0 } = useContractRead({
    address: sabotaddress,
    abi: sabotaddressAbi,
    functionName: 'balanceOf',
    args: [address],
    watch: true,
    enabled: !!address,
  });

  const { data: minimumStakeDuration = 0 } = useContractRead({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'minimumStakeDuration',
    watch: true,
    enabled: !!address,
  });

  const { data: stakedAmount = [0, 0, 0] } = useContractRead({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'stakes',
    args: [address],
    watch: true,
    enabled: !!address,
  });

  const { data: rewards = 0 } = useContractRead({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'calculateRewards',
    args: [address],
    watch: true,
    enabled: !!address,
  });

  const { data: canUnstakeWithoutMinDuration = false } = useContractRead({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'canUnstakeWithoutMinimumDuration',
    watch: true,
  });

  // Calculate claimable time for rewards
  const calculateClaimableTimeForRewards = () => {
    if (!stakedAmount || stakedAmount.length < 2) return null;

    const startTime = Number(stakedAmount[1]);
    const claimableTimestamp = startTime + Number(minimumStakeDuration);

    return new Date(claimableTimestamp * 1000).toLocaleString();
  };
  const claimableTimeForRewards = calculateClaimableTimeForRewards();

  // Calculate next claimable time
  const calculateNextClaimableTime = () => {
    if (!stakedAmount || stakedAmount.length < 3) return null;

    const lastClaimTime = Number(stakedAmount[2]); // lastClaimTime is at index 2
    const nextClaimableTimestamp = lastClaimTime + Number(minimumStakeDuration);

    return new Date(nextClaimableTimestamp * 1000).toLocaleString();
  };

  const nextClaimableTime = calculateNextClaimableTime();

  // Calculate reward rate
  const { data: rewardRate = 0 } = useContractRead({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'rewardRate',
    watch: true,
    enabled: !!address,
  });

  const calculateRewardRate = () => {
    return `${Number(rewardRate) / 1000}% Per Week`;
  };

  const rewardRateText = calculateRewardRate();

  // Check if staking duration has ended
  const isStakingDurationEnded = () => {
    if (!stakedAmount || stakedAmount.length === 0 || Number(stakedAmount[0]) === 0) return false;

    const startTime = Number(stakedAmount[1]);
    const claimableTimestamp = startTime + Number(minimumStakeDuration);

    return Date.now() > claimableTimestamp * 1000;
  };
  // Handle "Max" button click
  const handleMaxButtonClick = () => {
    if (balance) {
      const availableBalance = formatEther(balance);
      setStakingAmount(availableBalance);
      setStakingMessage(`You are about to stake ${availableBalance} SenseiBot tokens.`);
    }
  };

  // Handle input change
  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numbers and a single decimal point
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue)) {
      setStakingAmount(inputValue);
      setError('');
      setStakingMessage(`You are about to stake ${inputValue} SenseiBot tokens.`);
    } else {
      setError('Please enter a valid number.');
      setStakingMessage('');
    }
  };

  // Stake confirmation handler
  const handleStakeConfirm = async () => {
    if (!isConnected) {
      setError('Please connect your wallet first');
      return;
    }

    if (!stakingAmount || stakingAmount === '0') {
      setError('Please enter a valid staking amount');
      return;
    }

    const amount = parseEther(stakingAmount);
    if (amount < 20000 * 10 ** 18) {
      setError('Minimum staking amount is 20,000 tokens');
      return;
    }
    if (balance < amount) {
      setError('Insufficient balance');
      return;
    }

    setIsProcessing(true);
    setError('');

    try {
      if (allowance < amount) {
        setIsApproving(true);
        const approveTxHash = await writeContract({
          address: sabotaddress,
          abi: sabotaddressAbi,
          functionName: 'approve',
          args: [stakeContractaddress, amount.toString()],
        });

        setApproveHash(approveTxHash);
        setAllowstake(true);
        return;
      } else {
        const stakeTxHash = await writeContract({
          address: stakeContractaddress,
          abi: stakeContractAbi,
          functionName: 'stake',
          args: [amount.toString()],
        });
        // console.log("Stake transaction hash:", stakeTxHash);
      }
    } catch (error) {
      // console.error("Staking failed:", error);
      setError('Staking failed. Please try again.');
      toast.error('Staking failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isConfirmed && allowstake) {
      const amount = parseEther(stakingAmount);
      writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'stake',
        args: [amount.toString()],
      });
      setIsApproving(false);
      setApproveHash(null);
      setAllowstake(false);
      return;
    }
    if (!allowstake && isConfirmed) {
      if (typeof window.location.reload === "function") {
        window.location.reload();
        return;
      }
    } else if (writeContractError) {
      toast.error(`Transaction failed: User rejected the request`);
      // console.error("Transaction failed:", writeContractError);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [isConfirmed, writeContractError, writeContract]);

  // Claim rewards handler
  const handleClaimRewards = async () => {
    if (!isConnected) {
      setError('Please connect your wallet first');
      return;
    }

    setIsProcessing(true);
    setError('');

    try {
      const claimTxHash = await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'claimRewards',
      });
      // console.log("Claim rewards transaction hash:", claimTxHash);
    } catch (error) {
      // console.error("Claiming rewards failed:", error);
      setError('Claiming rewards failed. Please try again.');
      toast.error('Claiming rewards failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Reinvest rewards handler
  const handleReinvestRewards = async () => {
    if (!isConnected) {
      setError('Please connect your wallet first');
      return;
    }

    setIsProcessing(true);
    setError('');

    try {
      const reinvestTxHash = await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'reinvestRewards',
      });
    } catch (error) {
      // console.error("Reinvesting rewards failed:", error);
      setError('Reinvesting rewards failed. Please try again.');
      toast.error('Reinvesting rewards failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnstake = async () => {
    if (!isConnected) {
      setError('Please connect your wallet first');
      return;
    }
    setIsProcessing(true);
    setError('');
    try {
      const unstakeTxHash = await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'unstake',
      });
    } catch (error) {
      // console.error("Unstaking failed:", error);
      setError('Unstaking failed. Please try again.');
      toast.error('Unstaking failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <section id="aboutus" className="about-section">
      {isOwner ? <AdminNav /> : <Navbar />}
      {(isProcessing || isPending || isConfirming || isApproveConfirming) && <Loader />}

      <div className="dashboard-container wallet-section">
        <div className="container py-4">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="dashboard-title">Stake & Earn</h2>
            <div className="connect-wallet-btn right-bts right-bt newdevcls">
              <ConnectButton className="new" />
            </div>
          </div>

          <div className="row g-4">
            <div className="col-lg-6">
              <h3 className="section-title">My Balance</h3>
              <div className="balance-card">
                <div className="card-body position-relative">
                  <div className="card-content">
                    <h3 className="turtle-title">SenseiBot</h3>
                    <p className="vip-text">
                      SenseiBot tokens {balance ? Number(balance) / 10 ** 18 : '0'}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="card-number">
                      Last Claim Time: <strong>{stakedAmount && stakedAmount[2] ? new Date(Number(stakedAmount[2]) * 1000).toLocaleString() : 'MM/DD/YYYY'}</strong>
                    </p>
                    <p className="card-number">
                      Available Rewards: <strong>{Number(rewards) / 10 ** 18} SenseiBot</strong>
                    </p>
                  </div>
                  <div className="card-number">
                    {address ? `${address.slice(0, 6)}...${address.slice(-4)}` : '0x000...000'}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <p className="stake-subtitle">
                  {isStakingDurationEnded() ? (
                    <strong>Available to claim or Reinvest</strong>
                  ) : (
                    <>
                      You can claim or Re-invest rewards after:{" "}
                      <strong>
                        {
                          !isConnected
                            ? 'You can unstake your tokens after: MM/DD/YYYY'
                            : `${claimableTimeForRewards ? claimableTimeForRewards : '0'}`
                        }
                      </strong>
                    </>
                  )}
                </p>
              </div>
              <div className="d-flex gap-3 mt-3">
                <button
                  className="action-btn flex-grow-1"
                  onClick={handleClaimRewards}
                  disabled={!isConnected || Number(rewards) === 0 || isProcessing}
                >
                  Claim Rewards
                </button>
                <button
                  className="action-btn flex-grow-1"
                  onClick={handleReinvestRewards}
                  disabled={!isConnected || Number(rewards) === 0 || isProcessing}
                >
                  Reinvest Rewards
                </button>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="section-title">SenseiBot Staking</h3>
                <span className="rate-badge">{rewardRateText}</span>
              </div>
              <p className="stake-subtitle">Stake your token</p>

              <div className="staking-cards-wrapper d-flex justify-content-between">
                <div className="staking-card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="stake-label">
                        <i className="bi bi-lightning-fill me-2"></i>
                        Available to Stake
                      </div>
                      <span className="stake-amount">
                        {balance ? Number(balance) / 10 ** 18 : '0'}
                        &nbsp;
                        &nbsp;
                        <button
                          className="btn btn-sm btn-outline-secondary"
                          onClick={handleMaxButtonClick}
                          disabled={!isConnected || isProcessing}
                        >
                          Max
                        </button>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control mb-3 input-all"
                      placeholder="Enter amount to stake"
                      value={stakingAmount}
                      onChange={handleAmountChange}
                      disabled={isProcessing}
                    />
                    {error && <div className="text-danger mb-3">{error}</div>}
                    {stakingMessage && <div className="text-info mb-3">{stakingMessage}</div>}
                    <button
                      className="stake-btn"
                      onClick={handleStakeConfirm}
                      disabled={isProcessing || !isConnected}
                    >
                      Stake
                    </button>
                  </div>
                </div>

                <div className="staking-card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="stake-label">
                        <i className="bi bi-lightning-fill me-2"></i>
                        Staked
                      </div>
                      <span className="stake-amount">
                        {stakedAmount && stakedAmount.length > 0 ? Number(stakedAmount[0]) / 10 ** 18 : '0'}
                      </span>
                    </div>
                    <button
                      className="stake-btn"
                      onClick={handleUnstake}
                      disabled={
                        isProcessing ||
                        !stakedAmount ||
                        stakedAmount.length === 0 ||
                        Number(stakedAmount[0]) === 0 ||
                        (!canUnstakeWithoutMinDuration && !isStakingDurationEnded())
                      }
                      title={
                        !isConnected
                          ? 'You can unstake your tokens after: MM/DD/YYYY'
                          : `You can unstake your tokens after: ${claimableTimeForRewards ? claimableTimeForRewards : '0'}`
                      }
                    >
                      {isProcessing ? 'Processing...' : 'Unstake'}
                    </button>
                  </div>
                  <p className="stake-subtitle">
                    {stakedAmount && stakedAmount.length > 0 && Number(stakedAmount[0]) > 0 ? (
                      isStakingDurationEnded() ? (
                        <strong>Available to Unstake</strong>
                      ) : (
                        <>
                          You can unstake your tokens after:{" "}
                          <strong>
                            {!isConnected
                              ? 'You can unstake your tokens after: MM/DD/YYYY'
                              : `${claimableTimeForRewards ? claimableTimeForRewards : '0'}`
                            }
                          </strong>
                        </>
                      )
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default Staking;