import React, { useEffect, useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContractWrite, useWaitForTransactionReceipt, useWriteContract, useReadContract } from 'wagmi';
import { parseEther, formatEther, formatUnits } from 'ethers';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/loader';
import { stakeContractAbi, stakeContractaddress, usdtContractAbi, usdtContractAddress } from '../../common/mainnet-abi';
import AdminNav from '../../common/Adminnav';
import { toast } from 'react-toastify';

const UpdateStake = () => {
  const [minimumStakeAmount, setMinimumStakeAmount] = useState('');
  const [minimumStakeDurationInDays, setMinimumStakeDurationInDays] = useState('');
  const [minimumStakeDurationDisplay, setMinimumStakeDurationDisplay] = useState('');
  const [rewardRate, setRewardRate] = useState('');
  const [stakePeriodInDays, setStakePeriodInDays] = useState('');
  const [stakeAmountMessage, setStakeAmountMessage] = useState(''); // New state for the message

  // Error states
  const [minimumStakeAmountError, setMinimumStakeAmountError] = useState('');
  const [minimumStakeDurationError, setMinimumStakeDurationError] = useState('');
  const [rewardRateError, setRewardRateError] = useState('');
  const [stakePeriodError, setStakePeriodError] = useState('');
  const [buyPrice, setBuyPrice] = useState('');
  const [sellPrice, setSellPrice] = useState('');
  const [buyPriceError, setBuyPriceError] = useState('');
  const [sellPriceError, setSellPriceError] = useState('');
  const [isPendingSetPrices, setIsPendingSetPrices] = useState(false);
  const [withdrawTokenAddress, setWithdrawTokenAddress] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isPendingWithdraw, setIsPendingWithdraw] = useState(false);

  // Separate pending states for each operation
  const [isPendingMinimumStakeAmount, setIsPendingMinimumStakeAmount] = useState(false);
  const [isPendingMinimumStakeDuration, setIsPendingMinimumStakeDuration] = useState(false);
  const [isPendingRewardRate, setIsPendingRewardRate] = useState(false);
  const [isPendingStakePeriod, setIsPendingStakePeriod] = useState(false);

  // Fetch current values from the contract
  const { data: currentRewardRate } = useReadContract({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'rewardRate',
  });

  const { data: currentMinimumStakeAmount } = useReadContract({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'minimumStakeAmount',
  });

  const { data: currentMinimumStakeDuration } = useReadContract({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'minimumStakeDuration',
  });

  const { data: currentStakePeriod } = useReadContract({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'stakePeriod',
  });

  const {
    data: hash,
    error: writeContractError,
    writeContract,
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
  } = useWaitForTransactionReceipt({ hash });

  useEffect(() => {
    if (isConfirmed) {
      if (typeof window.location.reload === "function") {
        window.location.reload();
        return;
      }
    } else if (writeContractError) {
      toast.error(`Transaction failed: Admin rejected the request`);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [isConfirmed, writeContractError, writeContract, minimumStakeAmount]);

  // Convert BigNumber values to human-readable format
  const formatValue = (value, decimals = 18, convertToMinutes = false) => {
    if (!value) return 'Loading...';
    const formattedValue = formatUnits(value, decimals);
    if (convertToMinutes) {
      return (parseFloat(formattedValue) / 60).toFixed(2);
    }
    return formattedValue;
  };

  // Validate input values
  const validateInput = (value, fieldName, allowDecimals = false) => {
    if (!value) {
      return `${fieldName} cannot be empty`;
    }
    if (isNaN(value)) {
      return `${fieldName} must be a number`;
    }
    if (parseFloat(value) <= 0) {
      return `${fieldName} must be greater than 0`;
    }
    if (!allowDecimals && !Number.isInteger(parseFloat(value))) {
      return `${fieldName} must be an integer (no decimals)`;
    }
    return '';
  };

  // Convert days to seconds
  const convertDaysToSeconds = (days) => {
    return days * 24 * 60 * 60;
  };

  // Convert seconds to days
  const convertSecondsToDays = (seconds) => {
    return seconds / (24 * 60 * 60);
  };

  // Handle minimum stake duration input change (in days)
  const handleMinimumStakeDurationChange = (e) => {
    const value = e.target.value;
    if (value.includes('.')) {
      setMinimumStakeDurationError('Minimum Stake Duration must be an integer (no decimals)');
      return;
    }
    setMinimumStakeDurationInDays(value);

    const seconds = convertDaysToSeconds(value);
    setMinimumStakeDurationDisplay(`${value} day(s) (${seconds} seconds)`);

    if (value) {
      setMinimumStakeDurationError(`You can unstake after: ${value} day(s)`);
    } else {
      setMinimumStakeDurationError('');
    }
  };

  // Handle stake period input change (in days)
  const handleStakePeriodChange = (e) => {
    const value = e.target.value;
    if (value.includes('.')) {
      setStakePeriodError('Stake Period must be an integer (no decimals)');
      return;
    }

    setStakePeriodInDays(value);

    if (value) {
      setStakePeriodError(`Stake period: ${value} day(s)`);
    } else {
      setStakePeriodError('');
    }
  };

  // Handle reward rate input change
  const handleRewardRateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setRewardRate(value);

    const error = validateInput(value, 'Reward Rate', true);
    setRewardRateError(error);

    if (!error && value) {
      const scaledValue = parseFloat(value) * 1000;
      setRewardRateError(`Reward Rate: ${value}% (Scaled: ${scaledValue})`);
    } else {
      setRewardRateError('');
    }
  };

  // Handle minimum stake amount input change
  const handleMinimumStakeAmountChange = (e) => {
    const value = e.target.value;
    setMinimumStakeAmount(value);

    // Update the message based on the entered value
    if (value) {
      setStakeAmountMessage(`You are updating the minimum stake amount to ${value} Sbot`);
    } else {
      setStakeAmountMessage('');
    }
  };

  // Contract write functions with error handling
  const handleUpdateMinimumStakeAmount = async () => {
    const error = validateInput(minimumStakeAmount, 'Minimum Stake Amount');
    setMinimumStakeAmountError(error);
    if (error) return;

    setIsPendingMinimumStakeAmount(true);
    try {
      const amountInWei = parseEther(minimumStakeAmount);
      await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'updateMinimumStakeAmount',
        args: [amountInWei],
      });
    } catch (err) {
      // console.error('Error updating minimum stake amount:', err);
    } finally {
      setIsPendingMinimumStakeAmount(false);
    }
  };

  const handleUpdateMinimumStakeDuration = async () => {
    const error = validateInput(minimumStakeDurationInDays, 'Minimum Stake Duration');
    setMinimumStakeDurationError(error);
    if (error) return;

    setIsPendingMinimumStakeDuration(true);
    try {
      const durationInSeconds = convertDaysToSeconds(minimumStakeDurationInDays);
      await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'updateMinimumStakeDuration',
        args: [durationInSeconds],
      });
    } catch (err) {
      // console.error('Error updating minimum stake duration:', err);
    } finally {
      setIsPendingMinimumStakeDuration(false);
    }
  };

  const handleWithdraw = async () => {
    if (!withdrawTokenAddress || !withdrawAmount) {
      toast.error('Please enter a valid token address and amount');
      return;
    }
  
    setIsPendingWithdraw(true);
    try {
      const amountInWei = parseEther(withdrawAmount);
      await writeContract({
        address: usdtContractAddress,
        abi: usdtContractAbi,
        functionName: 'withdraw',
        args: [withdrawTokenAddress, amountInWei],
      });
    } catch (err) {
      toast.error('Withdrawal failed: ' + (err.message || 'Unknown error'));
    } finally {
      setIsPendingWithdraw(false);
    }
  };

  const handleUpdateRewardRate = async () => {
    const error = validateInput(rewardRate, 'Reward Rate', true);
    setRewardRateError(error);
    if (error) return;

    setIsPendingRewardRate(true);
    try {
      const scaledRewardRate = parseFloat(rewardRate) * 1000;
      await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'updateRewardRate',
        args: [scaledRewardRate],
      });
    } catch (err) {
      // console.error('Error updating reward rate:', err);
      toast.error('Failed to update reward rate');
    } finally {
      setIsPendingRewardRate(false);
    }
  };

  const handleUpdateStakePeriod = async () => {
    const error = validateInput(stakePeriodInDays, 'Stake Period');
    setStakePeriodError(error);
    if (error) return;

    if (!Number.isInteger(parseFloat(stakePeriodInDays))) {
      setStakePeriodError('Stake Period must be an integer (no decimals)');
      return;
    }

    setIsPendingStakePeriod(true);
    try {
      const periodInSeconds = convertDaysToSeconds(stakePeriodInDays);
      await writeContract({
        address: stakeContractaddress,
        abi: stakeContractAbi,
        functionName: 'updateStakePeriod',
        args: [periodInSeconds],
      });
    } catch (err) {
      // console.error('Error updating stake period:', err);
    } finally {
      setIsPendingStakePeriod(false);
    }
  };
  const handleBuyPriceChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow numbers and decimals
    setBuyPrice(value);

    const error = validateInput(value, 'Buy Price', true); // Allow decimals
    setBuyPriceError(error);

    if (!error && value) {
      setBuyPriceError(`Buy Price: ${value}`);
    } else {
      setBuyPriceError('');
    }
  };

  const handleSellPriceChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setSellPrice(value);

    const error = validateInput(value, 'Sell Price', true);
    setSellPriceError(error);

    if (!error && value) {
      setSellPriceError(`Sell Price: ${value}`);
    } else {
      setSellPriceError('');
    }
  };

  const handleSetPrices = async () => {
    const buyPriceError = validateInput(buyPrice, 'Buy Price', true);
    const sellPriceError = validateInput(sellPrice, 'Sell Price', true);
    setBuyPriceError(buyPriceError);
    setSellPriceError(sellPriceError);

    if (buyPriceError || sellPriceError) return;

    setIsPendingSetPrices(true);
    try {
      await writeContract({
        address: usdtContractAddress,
        abi: usdtContractAbi,
        functionName: 'setPrices',
        args: [parseEther(buyPrice), parseEther(sellPrice)],
      });
    } catch (err) {
      toast.error('Failed to set prices');
    } finally {
      setIsPendingSetPrices(false);
    }
  };

  return (
    <section id="aboutus" className="about-section">
      <AdminNav />
      {(isPendingMinimumStakeAmount || isPendingMinimumStakeDuration || isPendingRewardRate || isPendingStakePeriod || isConfirming) && <Loader />}
      <div className="dashboard-container wallet-section">
        <div className="container py-4">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="dashboard-title">Update & Stake</h2>
            <button className="connect-wallet-btn right-bts right-bt">
              <ConnectButton className="new" />
            </button>
          </div>

          <div className="row g-4">
            {/* First Column */}
            <div className="col-lg-6">
              <h3 className="section-title"></h3>
              <div className="balance-card">
                <div className="card-body position-relative">
                  <div className="card-content">
                    <h3 className="turtle-title">SenseiBot</h3>
                    <p className="vip-text">Admin</p>
                  </div>
                  <div className="card-number"></div>
                </div>
              </div>
              <div className="mt-4">
                <h4 className="section-title">Current Contract Values</h4>
              </div>
              <div className="mt-3">
                <p><strong>Reward Rate:</strong> {formatValue(currentRewardRate)} %</p>
                <input
                  type="text"
                  className="form-control mb-3 input-all"
                  placeholder="Enter Reward Rate"
                  value={rewardRate}
                  onChange={handleRewardRateChange}
                />
                {rewardRateError && <p className="text-danger">{rewardRateError}</p>}
                <button
                  className="action-btn flex-grow-1"
                  onClick={handleUpdateRewardRate}
                  disabled={isPendingRewardRate}
                >
                  {isPendingRewardRate ? 'Updating...' : 'Update Reward Rate'}
                </button>
              </div>
              <div className="mt-3">
                <p><strong>Stake Period:</strong> {convertSecondsToDays(formatValue(currentStakePeriod, 0))} day(s)</p>
                <input
                  type="text"
                  className="form-control mb-3 input-all"
                  placeholder="Enter Stake Period (in days)"
                  value={stakePeriodInDays}
                  onChange={handleStakePeriodChange}
                />
                {stakePeriodError && <p className="text-danger">{stakePeriodError}</p>}
                <button
                  className="action-btn flex-grow-1"
                  onClick={handleUpdateStakePeriod}
                  disabled={isPendingStakePeriod}
                >
                  {isPendingStakePeriod ? 'Updating...' : 'Update Stake Period'}
                </button>
              </div>
              <div className="mt-4">
                <h4 className="section-title">Set Buy & Sell Prices</h4>
                <div className="mt-3">
                  <input
                    type="text"
                    className="form-control mb-3 input-all"
                    placeholder="Enter Buy Price (in USDT)"
                    value={buyPrice}
                    onChange={handleBuyPriceChange}
                  />
                  {buyPriceError && <p className="text-danger">{buyPriceError}</p>}
                </div>
                <div className="mt-3">
                  <input
                    type="text"
                    className="form-control mb-3 input-all"
                    placeholder="Enter Sell Price (in USDT)"
                    value={sellPrice}
                    onChange={handleSellPriceChange}
                  />
                  {sellPriceError && <p className="text-danger">{sellPriceError}</p>}
                </div>
                <button
                  className="action-btn flex-grow-1"
                  onClick={handleSetPrices}
                  disabled={isPendingSetPrices}
                >
                  {isPendingSetPrices ? 'Updating...' : 'Set Prices'}
                </button>
              </div>
              <div className="mt-4">
                <h4 className="section-title">Withdraw (trade tokens or USDT) from the contract</h4>
                <div className="mt-3">
                  <input
                    type="text"
                    className="form-control mb-3 input-all"
                    placeholder="Enter Token Address"
                    value={withdrawTokenAddress}
                    onChange={(e) => setWithdrawTokenAddress(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <input
                    type="text"
                    className="form-control mb-3 input-all"
                    placeholder="Enter Amount to Withdraw"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                  />
                </div>
                <button
                  className="action-btn flex-grow-1"
                  onClick={handleWithdraw}
                  disabled={isPendingWithdraw}
                >
                  {isPendingWithdraw ? 'Withdrawing...' : 'Withdraw Tokens'}
                </button>
              </div>
            </div>

            {/* Second Column */}
            <div className="col-lg-6">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="section-title">SenseiBot Staking</h3>
              </div>
              <p className="stake-subtitle">Update your token & duration</p>

              <div className="staking-cards-wrapper d-flex justify-content-between">
                <div className="staking-card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="stake-label">
                        <i className="bi bi-lightning-fill me-2"></i>
                        Minimum Stake Amount
                      </div>
                      <div className="current-value">
                        {formatValue(currentMinimumStakeAmount)} Sbot
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control mb-3 input-all"
                      placeholder="Enter Stake Amount"
                      value={minimumStakeAmount}
                      onChange={handleMinimumStakeAmountChange}
                    />
                    {minimumStakeAmountError && <p className="text-danger">{minimumStakeAmountError}</p>}
                    {stakeAmountMessage && <p className="text-success">{stakeAmountMessage}</p>}
                    <button
                      className="stake-btn"
                      onClick={handleUpdateMinimumStakeAmount}
                      disabled={isPendingMinimumStakeAmount}
                    >
                      {isPendingMinimumStakeAmount ? 'Updating...' : 'Update Minimum Stake Amount'}
                    </button>
                  </div>
                </div>

                <div className="staking-card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="stake-label">
                        <i className="bi bi-lightning-fill me-2"></i>
                        Minimum Stake Duration
                      </div>
                      <div className="current-value">
                        {convertSecondsToDays(formatValue(currentMinimumStakeDuration, 0))} day(s)
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control mb-3 input-all"
                      placeholder="Enter Stake Duration (in days)"
                      value={minimumStakeDurationInDays}
                      onChange={handleMinimumStakeDurationChange}
                      pattern="\d*"
                    />
                    {minimumStakeDurationError && (
                      <p className="text-danger">{minimumStakeDurationError}</p>
                    )}
                    <button
                      className="stake-btn"
                      onClick={handleUpdateMinimumStakeDuration}
                      disabled={isPendingMinimumStakeDuration}
                    >
                      {isPendingMinimumStakeDuration ? 'Updating...' : 'Update Minimum Stake Duration'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default UpdateStake;