import React from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import UpdateStake from "../pages/pool/pool";
import Home from "../home/Home";
import Tobuy from "../pages/howtobuy";
import Howtostake from "../pages/howtostake";
import Staking from "../pages/stake";
import ErrorPage from "../common/error";

function App({isOwner}) {

    return (
        <>
            <Routes>
                <Route path="/" element={<UpdateStake />} />
                <Route path="/buy/sell" element={<Home />} />
                <Route path="/howtobuy" element={<Tobuy />} />
                <Route path="/howtostake" element={<Howtostake />} />
                <Route path="/staking" element={<Staking />} />
          {/* <Route path="/*" element={ !isOwner ? <ErrorPage   /> : ''} /> */}
            </Routes>

        </>
    );
}

export default App;