import React from 'react'
import sabot1 from "../../assets/images/bot-1.jpeg"
import sabot2 from "../../assets/images/bot-2.jpeg"
import sabot3 from "../../assets/images/bot-3.jpeg"
import sabot4 from "../../assets/images/bot-4.jpeg"
import sabot5 from "../../assets/images/bot-5.jpeg"
import sabot7 from "../../assets/images/bot-7.jpeg"
import BackToTop from '../../backtotop'
import Navbar from '../../common/nav'
import AdminNav from '../../common/Adminnav'



const Tobuy = ({isOwner}) => {
    return (
        <section id="aboutus" class="about-section">
            <div class="container">
            {isOwner?<AdminNav/>:<Navbar />}
                <div class="top-heading text-center">
                    <p class="heading-p">How to Buy Our Token During the Presale</p>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                    </div>
                </div>
                <div class="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="left-about">
                            <p class="about-p">1. Set up your wallet on the Binance Smart Chain
                            </p>
                            <p class="about-p-1">Make sure you have a compatible wallet, such as MetaMask or Trust Wallet. Configure the Binance Smart Chain network on your wallet.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="right-about">
                            <img src={sabot1} alt="" />
                        </div>
                    </div>
                    <div />
                </div>
                <div className="row align-item-center order-class">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">2. Ensure you have sufficient funds
                            </p>
                            <p class="about-p-1">You will need USDT (Tether) on the Binance Smart Chain network to purchase the token.<br />
                                Additionally, it's essential to have a small amount of BNB in your wallet to cover gas fees.<br />
                                {/* Token Buybacks and Burns: Uses profits to buy back and burn SBOT, thereby increasing the token's value. */}

                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot2} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">3. Connect to the presale platform
                            </p>
                            <p class="about-p-1">Access our official presale page and click on "Connect Wallet." Select your wallet and authorize the connection to our platform.


                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-item-center order-class">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">4. Buy the token
                            </p>
                            <p class="about-p-1">Enter the amount of USDT you want to use to purchase the token. Click on "Buy" and confirm the transaction in your wallet.
                                {/* and empowering ecosystem for users. <br /> Through incentives and a progressive participation model, we aim to
                        build the future of SenseiBot together. */}


                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot4} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">5. Authorize USDT spending
                            </p>
                            <p class="about-p-1">Your wallet will prompt you to approve the spending of USDT for the presale contract. Accept and confirm the transaction. Congratulations! You're now part of the presale!


                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot5} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-item-center order-class">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">6. Buy the token
                            </p>
                            <p class="about-p-1">The contract will be executed on the official launch day, at which point you will receive your tokens directly in your wallet.
                                <br />
                                To view the tokens in your wallet after the launch, you can manually add them using the token contract address.


                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot7} alt="" />
                        </div>
                    </div>
                </div>
                <div class="top-heading text-center">
                    <p class="heading-p">Token Contract Address:</p>
                    <p class="about-p-1">0xE3249Ec8902Db06BB3d75e2E9B3998F7F2A09CC5
                        <br />
                        Go to your wallet, select "Add Token," and paste the contract  <br />
                        address. The token will automatically appear after the launch.
                    </p>

                </div>
                <div className="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">Important Notes:
                            </p>
                            <p class="about-p-1">:- Always verify the token contract address and ensure you get it only from our official sources.
                                <p class="about-p-1">:- Beware of scams: Only use our official page to connect and make purchases.
                                </p>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            {/* <img src={sabot4} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <BackToTop />
        </section>
    )
}

export default Tobuy
