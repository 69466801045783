import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { Result } from 'ethers';
import { toast } from "react-toastify";

const BASE_URL = "http://34.134.213.246:8008";

const initialState = {
  value: 0,
  isLoading: false,
  allData: []
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    startLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    transData: (state, action) => {
      state.allData = action.payload;
    },
  },
});

export const { increment, startLoading, transData } = authSlice.actions;

export default authSlice.reducer;

// --------------------- Sign up API -----------------------
export function SignupApi({ userRegister, setUserRegister, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(startLoading(true));
      const response = await axios.post(`${BASE_URL}/api/user_register`, userRegister);

      if (response?.status === 200) {
        toast.success("Registered successfully");
        dispatch(startLoading(false));
        navigate("/login");
        setUserRegister({
          username: "",
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
        });
        return response?.data;
      }
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.email || "Registration failed");
      toast.error(error?.response?.data || "An error occurred");
    } finally {
      dispatch(startLoading(false));
    }
  };
}

// -------------------- Login user ---------------------------
export function LoginUser({ loginData, setLoginData, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(startLoading(true));
      const response = await axios.post(`${BASE_URL}/api/user_login`, loginData);

      if (response?.data?.status === 200) {
        dispatch(startLoading(false));
        // Uncomment if you want to store the token in localStorage
        // localStorage.setItem("token", response?.data?.token);
        // localStorage.setItem("username", loginData?.username);
        navigate("/otp");
        toast.success(response?.data?.message);
        setLoginData({ username: "", password: "" });
        return response?.data;
      } else {
        dispatch(startLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(startLoading(false));
      toast.error(error?.response?.data?.message || "Login failed");
    }
  };
}

// ---------------- Forgot password user -----------------
export function Forgotpassword({
  emailData,
  setEmailData,
  navigate,
  dispatch,
}) {
  return async () => {
    try {
      dispatch(startLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/password/reset/`,
        emailData
      );

      if (response?.status === 200) {
        dispatch(startLoading(false));
        localStorage.setItem("email", emailData?.email);
        toast.success(response?.data?.message);
        setEmailData({ email: "" });
        navigate("/login");
        return response?.data;
      } else {
        dispatch(startLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || "Error resetting password");
      dispatch(startLoading(false));
    }
  };
}

//-------------------fetch-Transactions------------

export function fetchTransactions() {
  return async (dispatch) => {
    // const address = localStorage.getItem('walletAddress')
    const apiKey = 'CA4EY5IERE8BHIM58UE77KKPQRBK5S7FUZ';
    try {
      dispatch(startLoading(true));
      const response = await axios.get(
        // `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=0&sort=desc&apikey=${apiKey}`
        `https://api.bscscan.com/api?module=logs&action=getLogs&address=0x7EEe06ff7F5763C96e6CDA4760d83E728eD738E1&topic0=0xff617f3e2f581705274c96e449cb6db5e8766c51e0a0fa4e9c425db2398cfb6c&apikey=CA4EY5IERE8BHIM58UE77KKPQRBK5S7FUZ`
        // `https://api-testnet.bscscan.com/api?module=logs&action=getLogs&address=0x797190b32d5A8f62961d29D063e709D4374D4DCf&topic0=0xff617f3e2f581705274c96e449cb6db5e8766c51e0a0fa4e9c425db2398cfb6c&apikey=CA4EY5IERE8BHIM58UE77KKPQRBK5S7FUZ`
      );

      if (response?.status === 200) {
        dispatch(startLoading(false));
        if (response?.data?.status == 1) {
          dispatch(transData(response?.data?.result));
        }
        return response?.data;
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || "Failed to fetch transactions"
      );
    } finally {
      dispatch(startLoading(false));
    }
  };

}

export function sendMessage(payload) {
  return async (dispatch) => {
    // const BOT_TOKEN = '7622965849:AAGBoMoc1QMvJa2IqazLayOLM0TmM014v68';
    const BOT_TOKEN = '8049560958:AAFbBPRC6P-pyGFQR2eUKBqfMt8naNwJdok';
    // const CHAT_ID = "5876280391";
    const CHAT_ID = "6623524353";
    const API_ENDPOINT = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;
    https://api.telegram.org/bot123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11/getMe
    try {
      dispatch(startLoading(true));
      const text = `
              Transaction Details:
              Timestamp: ${payload.timestamp}
              Tokens Bought: ${payload.tokensBought}
              USDT Spent: ${payload.usdtSpent}`;

      if (!text.trim()) {
        throw new Error("Message text is empty.");
      }
      const requestBody = {
        chat_id: CHAT_ID,
        text: text,
      };
      const response = await axios.post(API_ENDPOINT,requestBody);
      if (response?.status === 200) {
        dispatch(startLoading(false));
        return response?.data;
      } else {
        throw new Error("Failed to send message to Telegram.");
      }
    } catch (error) {
      // console.error("Telegram API Error:", error);
      toast.error(
        // error?.response?.data?.description || "Failed to send message."
      );
    } finally {
      dispatch(startLoading(false));
    }
  };
}

// export function sellMessage(payload) {
//   return async (dispatch) => {
//     // const BOT_TOKEN = '7622965849:AAGBoMoc1QMvJa2IqazLayOLM0TmM014v68';
//     const BOT_TOKEN = '8049560958:AAFbBPRC6P-pyGFQR2eUKBqfMt8naNwJdok';
//     // const CHAT_ID = "8049560958";
//     const CHAT_ID = "5876280391";
//     const API_ENDPOINT = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;
//     https://api.telegram.org/bot123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11/getMe

//     try {
//       dispatch(startLoading(true));
//       const text = `
//               Transaction Details:
//               Timestamp: ${payload.timestamp}
//               USDT Bought: ${payload.tokensBought}
//               SBOT Spent: ${payload.usdtSpent}`;

//       if (!text.trim()) {
//         throw new Error("Message text is empty.");
//       }
//       const requestBody = {
//         chat_id: CHAT_ID,
//         text: text,
//       };
//       const response = await axios.post(API_ENDPOINT, requestBody);
//       if (response?.status === 200) {
//         dispatch(startLoading(false));
//         return response?.data;
//       } else {
//         throw new Error("Failed to send message to Telegram.");
//       }
//     } catch (error) {
//       // console.error("Telegram API Error:", error);
//       toast.error(
//         // error?.response?.data?.description || "Failed to send message."
//       );
//     } finally {
//       dispatch(startLoading(false));
//     }
//   };
// }


// const getChatId = async () => {
//   const BOT_TOKEN = '7622965849:AAGBoMoc1QMvJa2IqazLayOLM0TmM014v68';
//   const API_ENDPOINT = `https://api.telegram.org/bot${BOT_TOKEN}/getUpdates`;
//   try {
//     const response = await axios.get(API_ENDPOINT);
//     if (response.data.ok && response.data.result.length > 0) {
//       const chatId = response.data.result[0].message.chat.id;
//       console.log('Chat ID:', chatId);
//       return chatId;
//     } else {
//       console.log('No updates found. Send a message to your bot first.');
//     }
//   } catch (error) {
//     console.error('Error fetching chat ID:', error);
//   }
// };



