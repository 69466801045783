import React from 'react';

const ErrorPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="text-center p-5  rounded shadow" style={{background:"#0d1117", width:'100%', maxWidth:'750px'}}>
        <h1 className="display-1 text-danger font-weight-bold">404</h1>
        <p className="lead text-secondary">Oops! Page not found.</p>
        <p className="text-white mb-4 unavailable">
          The page you are looking for might have been removed, <br></br> had its name changed, or is temporarily unavailable.
        </p>
        <a href="/" className="error-btn">
          Go to Homepage
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;