import React, { useState, useEffect, useCallback } from "react";
import fiveImg from "../assets/images/five-img.png";
import coinNew from "../assets/images/SENSEIBOT.png";
import "../App.css";
import Web3 from 'web3';
import Loader from "../Loader/loader";
import { toast } from "react-toastify";
import { parseUnits, formatUnits } from 'ethers';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { fetchTransactions, sendMessage, transData } from "../redux/auth/auth";
import { useAccount, useConnect, useDisconnect, useBalance, useWriteContract, useWaitForTransactionReceipt, useContractRead, } from 'wagmi';
import { sabotContractaddress, sabotContractaddressAbi, usdtApproveAddress, usdtApproveAddressAbi, usdtContractAbi, usdtContractAddress } from "../common/mainnet-abi";
import JSBI from "jsbi";
import BackToTop from "../backtotop";
import { BN } from "bn.js";
import Navbar from "../common/nav";
import AdminNav from "../common/Adminnav";
import { readContract, waitForTransaction } from "@wagmi/core";




function Home({ isOwner }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [usdTokens, setUsdTokens] = useState("");
  const [loadingButton, setLoadingButton] = useState(null);
  const walletAddress = address;
  const [buyInputEth, setBuyInputEth] = useState("");
  const [sellInputEthers, setSellInputEthers] = useState("");
  const [filmTokens, setFilmTokens] = useState("");
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [balance, setBalance] = useState(null);
  const [usdt, setUsdt] = useState(null);
  const [approvalSuccess, setApprovalSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const allData = useSelector((state) => state.auth.allData);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());


  function calculateTimeRemaining() {
    const targetDate = new Date("2025-01-20T00:00:00");
    const currentDate = new Date();
    const diff = targetDate - currentDate;

    if (diff <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { days, hours, minutes, seconds };
  }
  const { days, hours, minutes, seconds } = timeRemaining;


  const [currentPage, setCurrentPage] = useState(1);
  const [stateBuy, setStateBuy] = useState("");
  // const infuraProjectId =
  //   "06DLwXs4GGm2E7epZyJwPEivhkOSGmx/pSiX1O/OCTG8TyOTFSmN5g";
  // const infuraUrl = `https://mainnet.infura.io/v3/${infuraProjectId}`;

  const adrs = address;



  const decodeHexData = (hexString, txn) => {
    if (hexString.startsWith("0x")) {
      hexString = hexString.slice(2);
    }

    const chunkSize = 64;
    const chunks = [];
    for (let i = 0; i < hexString.length; i += chunkSize) {
      chunks.push(hexString.slice(i, i + chunkSize));
    }

    const buyerHex = chunks[0]?.slice(-40);

    if (!buyerHex) {
      return {};
    }

    const buyer = `0x${buyerHex}`;
    const usdtSpent = JSBI.BigInt(`0x${chunks[1]}`).toString();
    const tokensBought = JSBI.BigInt(`0x${chunks[2]}`).toString();

    const hexTimeStamp = txn.timeStamp;
    const unixTimeStamp = parseInt(hexTimeStamp, 16);
    const timestamp = new Date(unixTimeStamp * 1000).toLocaleString();

    return {
      to: txn.address,
      from: buyer,
      hash: txn.transactionHash,
      usdtSpent,
      usdt: (parseFloat(usdtSpent) / 10 ** 18).toFixed(6),
      tokensBought,
      token: (parseFloat(tokensBought) / 10 ** 18).toFixed(6),
      timestamp,
    };
  };

  let decodedData = allData?.filter(ele => {
    if (!walletAddress) return false;
    const decoded = decodeHexData(ele.data, ele);

    return decoded?.from == walletAddress.toLowerCase();
  })

    .map(ele => {
      const decodedEle = decodeHexData(ele.data, ele);
      return decodedEle;
    });

  const reversedTransactions = [...decodedData || []].reverse();

  // Pagination logic
  const ITEMS_PER_PAGE = 5;
  const totalItems = reversedTransactions?.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = reversedTransactions.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    dispatch(fetchTransactions(dispatch))
    if (adrs == "undefined") {
      dispatch(transData())
    }
  }, [adrs])

  const {
    data: hash,
    isPending,
    error: writeContractError,
    writeContract
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed
  } = useWaitForTransactionReceipt({ hash });


  const handleBuyInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setBuyInputEth(value);
  
    const sbotTokens = value * 100;
    setFilmTokens(sbotTokens.toFixed(2));
  };

  const handleSellInputEtheres = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); 
    setSellInputEthers(value);
  
    const usdValue = value * 0.01;
    setUsdTokens(usdValue.toFixed(2));
  };

  const handlePayload = (buyInputEth, filmTokens) => {
    const payload = {
      usdtSpent: buyInputEth,
      tokensBought: filmTokens,
      timestamp: new Date().toLocaleString(),
    };

    console.log("payload", payload);
    dispatch(sendMessage(payload, dispatch));
  };


  useEffect(() => {
    if (isConfirmed && isConfirmed === true) {
      setLoadingButton(true);

      const delay = 20000;

      const resetTransactionState = () => {

        setBuyInputEth("");
        setFilmTokens("");
        setSellInputEthers("");
        setUsdTokens("");
        setLoadingButton(null);
      };
      setTimeout(() => {
        if (isConfirmed) {
          // toast.success("Transaction successful!");
          setLoadingButton('action');
          dispatch(fetchTransactions(dispatch));
          window.location.reload();
          return;
        }
        resetTransactionState();
      }, delay);
    }
    else {
      if (writeContractError) {
        setApprovalSuccess(false)
        toast.error(`Transaction failed: User rejected the request.`);
        window.location.reload()
        return
      }
    }
  }, [isConfirmed, writeContractError]);

  useEffect(() => {
    const fetchBalance = async () => {
      if (!window.ethereum) {
        // console.error("MetaMask is not installed.");
        setBalance("0.00");
        return;
      }

      const web3 = new Web3(window.ethereum);

      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        let balanceInTokens;

        const presaleContract = new web3.eth.Contract(usdtContractAbi, usdtContractAddress);

        // Check if isPresaleActive method exists
        if (presaleContract.methods.isPresaleActive) {
          const isPresaleActive = await presaleContract.methods.isPresaleActive().call();

          if (isPresaleActive) {
            const presaleBalanceInWei = await presaleContract.methods.getPresaleBalance(account).call();
            balanceInTokens = web3.utils.fromWei(presaleBalanceInWei, 'ether');
          } else {
            const sabotContract = new web3.eth.Contract(sabotContractaddressAbi, sabotContractaddress);
            const balanceInWei = await sabotContract.methods.balanceOf(account).call();
            balanceInTokens = web3.utils.fromWei(balanceInWei, 'ether');
          }
        } else {
          // console.error("isPresaleActive method is not found in the contract.");
          setBalance("0.00");
          return;
        }

        // Update balance
        setBalance(Number(balanceInTokens).toFixed(2));
      } catch (err) {
        // console.error("Failed to fetch balance:", err);
        setBalance("0.00");
      }
    };

    fetchBalance();
  }, [adrs]);

  useEffect(() => {
    const fetchUSDTBalance = async () => {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const web3 = new Web3(window.ethereum);

          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const account = accounts[0];

          const contract = new web3.eth.Contract(usdtApproveAddressAbi, usdtApproveAddress);

          const balanceInWei = await contract.methods.balanceOf(account).call();
          const balanceInUsdt = web3.utils.fromWei(balanceInWei, 'ether');

          const formattedBalance = Number(balanceInUsdt).toFixed(2);
          setUsdt(formattedBalance);

          if (formattedBalance === true || formattedBalance === undefined) {
            window.location.reload();
          }
        } catch (err) {
          setUsdt('0');
        }
      }
    };

    fetchUSDTBalance();
  }, [adrs]);


  // Fetch USDT allowance
  const { data: allowanceData } = useContractRead({
    address: usdtApproveAddress,
    abi: usdtApproveAddressAbi,
    functionName: 'allowance',
    args: [address, usdtContractAddress],
    watch: true,
  });

  // Fetch USDT balance
  const { data: balanceData } = useContractRead({
    address: usdtApproveAddress,
    abi: usdtApproveAddressAbi,
    functionName: 'balanceOf',
    args: [address],
    watch: true,
  });
  const { data: tokenData } = useContractRead({
    address: sabotContractaddress,
    abi: sabotContractaddressAbi,
    functionName: 'balanceOf',
    args: [usdtContractAddress],
    watch: true,
  });

  const checkAllowance = useCallback(
    async (amount) => {
      if (!allowanceData) {
        // console.error("Allowance data is undefined");
        return false;
      }
      const currentAllowance = new BN(allowanceData.toString());
      const requiredAmount = new BN(parseUnits(amount, 18).toString());
      return currentAllowance.gte(requiredAmount);
    },
    [allowanceData]
  );
  const [isApprovalNeeded, setIsApprovalNeeded] = useState(false);
  const checkBalance = useCallback(
    (amount) => {
      if (!balanceData) {
        // console.error("Balance data is undefined");
        return false;
      }
      const userBalance = new BN(balanceData.toString());
      const requiredAmount = new BN(parseUnits(amount, 18).toString());
      return userBalance.gte(requiredAmount);
    },
    [balanceData]
  );
  const tokenBalance = useCallback(
    (amount) => {
      if (!tokenData) {
        return false;
      }
      const userBalance = new BN(tokenData.toString());
      const requiredAmount = new BN(parseUnits(amount, 18).toString());
      return userBalance.gte(requiredAmount);
    },
    [tokenData]
  );

  const handleApproveOrBuy = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }
    const delay = 20000;
    setLoadingButton('action');

    try {

      const amount = new BN(parseUnits(buyInputEth, 18).toString());

      // Check USDT balance
      const hasEnoughBalance = checkBalance(buyInputEth);
      if (!hasEnoughBalance) {
        toast.error("You do not have enough USDT balance");
        setLoadingButton(null);
        return;
      }
      const hasEnoughtokenBalance = tokenBalance(buyInputEth);
      if (!hasEnoughtokenBalance) {
        toast.error("sufficient tokens in contract");
        setLoadingButton(null);
        return;
      }

      // Check USDT allowance
      const hasEnoughAllowance = await checkAllowance(buyInputEth);
      // console.log('objehasEnoughAllowancect', hasEnoughAllowance)
      if (!hasEnoughAllowance) {
        setIsApprovalNeeded(true);

        setLoadingButton('action');

        await writeContract({
          address: usdtApproveAddress,
          abi: usdtApproveAddressAbi,
          functionName: "approve",
          args: [usdtContractAddress, amount.toString()],
        });
      }
      else {
        setIsApprovalNeeded(false);
        await writeContract({
          address: usdtContractAddress,
          abi: usdtContractAbi,
          functionName: "buyTokens",
          args: [amount.toString()],
        });
        setTimeout(() => {
          handlePayload(buyInputEth, filmTokens);
        }, delay);
        setLoadingButton('action');
        return;
      }
    } catch (error) {
      // console.error(error);
      toast.error("Action failed. Please check your wallet connection.");
    } finally {
      setLoadingButton(null);
    }
  };



  const { data: Sbotbalance } = useContractRead({
    address: sabotContractaddress,
    abi: sabotContractaddressAbi,
    functionName: 'balanceOf',
    args: [address],
    watch: true,
  });

  const { data: USallowanceData } = useContractRead({
    address: sabotContractaddress,
    abi: sabotContractaddressAbi,
    functionName: 'allowance',
    args: [address, usdtContractAddress],
    watch: true,
  });

  const { data: USDTData } = useContractRead({
    address: usdtContractAddress,
    abi: usdtContractAbi,
    functionName: 'balanceOf',
    args: [usdtContractAddress],
    watch: true,
  });
  const { data: presale } = useContractRead({
    address: usdtContractAddress,
    abi: usdtContractAbi,
    functionName: 'isPresaleActive',
    args: [usdtContractAddress],
    watch: true,
  });

  const handleSellToken = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }

    if (!sellInputEthers || isNaN(Number(sellInputEthers)) || Number(sellInputEthers) <= 0) {
      toast.error("Please enter a valid quantity to sell");
      return;
    }

    if (presale) {
      toast.error('Presale is active. Selling tokens is not allowed during presale.');
      return;
    }
    setLoadingButton('sell');

    try {
      const amount = parseUnits(sellInputEthers, 18);
      if (!Sbotbalance || Sbotbalance < amount) {
        toast.error("You don't have enough sbot balance to sell");
        setLoadingButton(null);
        return;
      }
      if (!USDTData || USDTData < amount) {
        toast.error("Insufficient USDT balance in the contract");
        setLoadingButton(null);
        return;
      }
      const currentAllowance = USallowanceData;

      if (currentAllowance < amount) {
        await writeContract({
          address: sabotContractaddress,
          abi: sabotContractaddressAbi,
          functionName: "approve",
          args: [usdtContractAddress, amount],
        });

      } else {
        await writeContract({
          address: usdtContractAddress,
          abi: usdtContractAbi,
          functionName: "sellTokens",
          args: [amount],
        });

        await dispatch(fetchTransactions());
      }
    } catch (error) {
      setLoadingButton(null);
      // toast.error(`Sell transaction failed: ${error.message}`);
    }
  };


  const handleNextPage = (event) => {
    event.preventDefault();
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = (event) => {
    event.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const showApproveButton = USallowanceData < parseUnits(sellInputEthers || '0', 18);


  return (
    <section id="aboutus" className="about-section">
      <div className="bf-footer only-das-footer">
        {stateBuy != "" && transactionConfirmed ? <Loader /> : ""}
        {/* Header Connect Button */}
        <section className="header-custom">
          {isOwner ? <AdminNav /> : <Navbar />}
          <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
              <div className="navbar-brand logo-container">
                <span className="logo-text">SBOT Balance: {balance ? balance : '0'}</span>
                <span className="logo-text">USDT Balance ${usdt ? usdt : '0'}</span>
              </div>
              <div className="right-bt newdevcls">
                <ConnectButton />
              </div>
            </div>
          </nav>
        </section>

        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="DECENTRALIZED-1">
                  <p className="DECENTRALIZED-p-1" style={{ marginTop: "50px" }}>
                    {/* YOUR MASTER IN <br />
                  THE ART OF <br />
                  AUTOMATED TRADING */}
                    SENSEIBOT
                  </p>
                  <div className="countdown-container">
                    <h1>Token sale ends in:</h1>
                    <div className="timer">
                      <div className="time-section">
                        <span className="time">{days}</span>
                        <span className="label">Days</span>
                      </div>
                      <span className="separator">:</span>
                      <div className="time-section">
                        <span className="time">{hours.toString().padStart(2, "0")}</span>
                        <span className="label">Hours</span>
                      </div>
                      <span className="separator">:</span>
                      <div className="time-section">
                        <span className="time">{minutes.toString().padStart(2, "0")}</span>
                        <span className="label">Minutes</span>
                      </div>
                      <span className="separator">:</span>
                      <div className="time-section">
                        <span className="time">{seconds.toString().padStart(2, "0")}</span>
                        <span className="label">Seconds</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <p
                  style={{
                    color: "white",
                    marginBottom: "20px",
                    marginTop: "20px",
                    padding: "10px 20px",
                    textAlign: "center",
                  }}
                >
                  SBOT aims to move beyond speculation by offering a tool
                  that generates real value through a participation model
                  and an automated, efficient trading bot. The integration
                  of mechanisms like buyback, burning,<br /> and liquidity
                  provision ensures long-term sustainability. Furthermore,
                  future projects like educational games powered by SBOT
                  promise significant growth potential.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="complet-bit">
          <img className="acc-img-2" src={fiveImg} alt="" />
          <div className="container">
            <div
              className="row flex-strch resp_modal"
              style={{ display: "flex", gap: "20px", marginLeft: "100px" }}
            >
              <div className="col-lg-6">
                <div
                  className="strch-1"
                  style={{
                    padding: "20px",
                    boxSizing: "border-box",
                    overflow: "hidden",
                  }}
                >
                  <img className="green-img" src={coinNew} alt="" />
                  <div className="bor-bottom">
                    <p className="red-img-right">
                      BUY
                    </p>
                  </div>
                  <div className="bottom-shadow"></div>
                  <div className="buy-flex">
                    <ul>
                      <li style={{ marginBottom: "15px" }}>
                        <label htmlFor="">Quantity (USDT)</label>
                        <input
                          className="input-all"
                          type="text"
                          value={buyInputEth}
                          onChange={handleBuyInputChange}
                          disabled={!isConnected}
                          style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
                        />
                      </li>
                      <li style={{ marginBottom: "15px" }}>
                        <label htmlFor="">SBOT Tokens</label>
                        <input
                          className="input-all"
                          type="text"
                          value={filmTokens}
                          readOnly
                          style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
                        />
                      </li>
                      <li>
                        <button
                          className="BUY-BT"
                          style={{ width: "100%" }}
                          onClick={handleApproveOrBuy}
                          disabled={!isConnected || loadingButton === "action"}
                        >
                          {loadingButton === "action"
                            ? "Processing..."
                            : isApprovalNeeded
                              ? "APPROVE SBOT"
                              : "BUY SBOT NOW"}
                        </button>


                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Sell section */}
              <div className="col-lg-6">
                <div
                  className="strch-1"
                  style={{
                    padding: "20px",
                    boxSizing: "border-box",
                    overflow: "hidden",
                  }}
                >
                  <img className="green-img" src={coinNew} alt="" />
                  <div className="bor-bottom">
                    <p className="red-img-left">SELL</p>
                  </div>
                  <div className="bottom-shadow"></div>
                  <div className="buy-flex">
                    <ul>
                      <li style={{ marginBottom: "15px" }}>
                        <label htmlFor="sellQuantity">SBOT Tokens</label>
                        <div className="right-buy input-container">
                          <input
                            id="sellQuantity"
                            className="input-all"
                            type="text"
                            value={sellInputEthers}
                            onChange={handleSellInputEtheres}
                            disabled={isVisible}
                            style={{
                              width: "100%",
                              padding: "8px",
                              boxSizing: "border-box",
                            }}
                          />
                        </div>
                      </li>
                      <li style={{ marginBottom: "15px" }}>
                        <label htmlFor="usdTokens">Quantity (USDT)</label>
                        <div className="right-buy">
                          <input
                            id="usdTokens"
                            className="input-all"
                            type="text"
                            value={usdTokens}
                            readOnly
                            style={{
                              width: "100%",
                              padding: "8px",
                              boxSizing: "border-box",
                            }}
                          />
                        </div>
                      </li>
                      <li>
                        <button
                          className="BUY-BT sell-bt"
                          style={{ width: "100%" }}
                          onClick={handleSellToken}
                          disabled={!isConnected || loadingButton === 'sell'}
                        >
                          {loadingButton === 'sell' ? 'Processing...' : showApproveButton ? 'Approve SBOT' : 'SELL SBOT NOW'}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              {/*sell section end*/}
              <div className="row margin-uk"></div>
            </div>
            <div
              className="container"
              style={{
                maxWidth: "1140px",
                marginLeft: "auto",
                marginRight: "auto",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              <div
                className="row justify-content-center"
                style={{ marginTop: "20px", marginLeft: "-15px", marginRight: "-15px" }}
              >
                <div
                  className="col-lg-12 mx-auto"
                  style={{ paddingLeft: "15px", paddingRight: "15px" }}
                >
                  <div className="all-same-div">
                    <div className="top-h">
                      <p>TRANSACTIONS HISTORY</p>
                    </div>
                    <div className="table-btc">
                      <div style={{ overflowX: "auto" }}>
                        <table>
                          <thead>
                            <tr style={{ color: "white" }}>
                              <th>Serial No</th>
                              <th>Hash</th>
                              <th>From</th>
                              <th>To</th>
                              <th>USDT Spent</th>
                              <th>Tokens Bought</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody style={{ color: "white", fontSize: "12px", textAlign: "center" }}>
                            {currentTransactions.length > 0 ? (
                              currentTransactions.map((tx, ind) => {
                                const usdtSpent = parseFloat(tx.usdtSpent) / 10 ** 18;
                                const tokensBought = parseFloat(tx.tokensBought) / 10 ** 18;

                                return (
                                  <tr key={ind}>
                                    <td>{startIndex + ind + 1}</td>
                                    <td>{tx.hash?.slice(0, 20)}...</td>
                                    <td>{tx.from?.slice(0, 20)}...</td>
                                    <td>{tx.to?.slice(0, 20)}...</td>
                                    <td>{usdtSpent.toFixed(2)}</td>
                                    <td>{tokensBought.toFixed(2)}</td>
                                    <td>{tx.timestamp}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" style={{ textAlign: "center", padding: "100px 0" }}>
                                  <div className="border-price-content">
                                    <p className="border-price-p" style={{ textAlign: "center" }}>
                                      NO DATA FOUND
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          gap: "10px",
                          marginTop: "20px",
                        }}
                      >
                        <button
                          onClick={handlePreviousPage}
                          style={{
                            marginRight: "10px",
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            visibility: currentPage === 1 ? "hidden" : "visible",
                            background: "none",
                            border: "none",
                            fontSize: "14px",
                          }}
                        >
                          Previous
                        </button>
                        <button
                          onClick={handleNextPage}
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            visibility: currentPage === totalPages ? "hidden" : "visible",
                            background: "none",
                            border: "none",
                            fontSize: "14px",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Border Price Section */}
        <section className="border-price-sec justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <div className="border-price-content">
                  <p className="border-price-p" style={{ textAlign: "center", color: "#000" }}>
                    SenseiBot (SBOT)
                  </p>
                </div>
                <p
                  style={{
                    color: "#000",
                    marginBottom: "20px",
                    marginLeft: "80px",
                    marginRight: "80px",
                    textAlign: "center",
                  }}
                >
                  SenseiBot (SBOT) is a utility token designed to create
                  value for the crypto community through an innovative
                  ecosystem based on a mining like participation model.
                  SBOT enables users to earn rewards by contributing to
                  the ecosystem, driven by an advanced trading bot. In the
                  long term, SBOT will evolve into a currency for exchange
                  and payment within the ecosystem, promoting
                  sustainability and real utility. The project is supported by
                  "Ryushi Ryu," a team of expert traders and developers,
                  focused on transforming interactions with the crypto
                  world.
                </p>
              </div>
            </div>
            <BackToTop />
          </div>
        </section>
      </div >
    </section>
  );
}


export default Home;
