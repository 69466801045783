import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignUp from "./app/pages/SignUp";
import ForgotPassword from "./app/pages/ForgotPassword";
import Home from "./home/Home";
import Login from "./app/pages/Login";
import OtpVerify from "./app/pages/OtpVerify";
import Dashboard from "./app/admin/pages/Dashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./Loader/loader";
import { useSelector } from "react-redux";
import Updatedhome from "./pages/updatehome";
import Tobuy from "./pages/howtobuy";
import Staking from "./pages/stake";
import { useContractRead, useAccount } from 'wagmi';
import { stakeContractAbi, stakeContractaddress } from "./common/mainnet-abi";
import AdminRoutes from "./AdminLayout/AdminRoutes"
import Howtostake from "./pages/howtostake";
import ErrorPage from "./common/error";


function App() {
  const { isLoading } = useSelector((state) => state.auth);
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const { data: ownerAddress } = useContractRead({
    address: stakeContractaddress,
    abi: stakeContractAbi,
    functionName: 'owner',
  });



  const { address: userAddress } = useAccount();


  useEffect(() => {
    const timer = setTimeout(() => {
      if (ownerAddress != undefined && userAddress != undefined) {
        setIsOwner(ownerAddress === userAddress);
      } else {
        setIsOwner(null)
      }
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [ownerAddress, userAddress]);

  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      <Router>
        <Routes>
          {loading ? (
           <>
            <Route
              path={"/"}
              element={<Loader />}
            />
            <Route
              path={"/admin"}
              element={<Loader />}
            />
           </>
          ) : (
            <>
              <Route
                path="/"
                element={
                  isOwner && userAddress ? (
                    <Navigate to="/admin" replace />
                  ) : (
                    <Updatedhome />
                  )
                }
              />
              <Route
                path="/admin"
                element={
                  isOwner && userAddress ? (
                    <AdminRoutes isOwner={isOwner} />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
            </>
          )}
          <Route path="/buy/sell" element={<Home isOwner={isOwner} />} />
          <Route path="/howtobuy" element={<Tobuy isOwner={isOwner} />} />
          <Route path="/howtostake" element={<Howtostake isOwner={isOwner} />} />
          <Route path="/staking" element={<Staking isOwner={isOwner} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp" element={<OtpVerify />} />
          <Route path="*" element={isOwner ? <Loader /> : <ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;