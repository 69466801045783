import React from 'react'
import sabot1 from "../../assets/images/bot-5.jpeg"
import sabot2 from "../../assets/images/bot-2.jpeg"
import sabot3 from "../../assets/images/bot-3.jpeg"
import sabot4 from "../../assets/images/bot-4.jpeg"
import sabot5 from "../../assets/images/bot-5.jpeg"
import sabot7 from "../../assets/images/bot-7.jpeg"
import BackToTop from '../../backtotop'
import Navbar from '../../common/nav'
import AdminNav from '../../common/Adminnav'



const Howtostake = ({isOwner}) => {
    return (
        <section id="aboutus" class="about-section">
            <div class="container">
            {isOwner?<AdminNav/>:<Navbar />}
                <div class="top-heading text-center">
                    <p class="heading-p">Crypto Master Pool</p>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                    </div>
                </div>
                <div class="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="left-about">
                            <p class="about-p">1. Deposits and Participation
                            </p>
                            <p class="about-p-1">Accepted Tokens: Users can participate in the pool by depositing exclusively SBOT tokens.
                            </p>
                            <p class="about-p-1">Minimum Amount: The minimum participation amount is 20,000 SBOT, with no maximum limit.
                            </p>
                            <p class="about-p-1">Participation Conditions: Funds must remain in the pool for at least 15 days to be eligible for rewards. Withdrawals made before this period will not receive any rewards.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="right-about">
                            <img src={sabot1} alt="" />
                        </div>
                    </div>
                    <div />
                </div>
                <div className="row align-item-center order-class">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">2. Rewards and Withdrawals
                            </p>
                            <p class="about-p-1">Reward Distribution: Rewards in SBOT will be distributed weekly.
                            </p>
                            <p class="about-p-1">Withdrawal Options: Users can sell SBOT in order to withdraw their rewards in USDT.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot2} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">3. Trading Bot Strategy
                            </p>
                            <p class="about-p-1">Advanced Strategies: The AI trading bot uses a combination of arbitrage, scalping, and day trading techniques. It employs advanced technical indicators and complements them with fundamental analysis.
                            </p>
                            <p class="about-p-1">Efficiency Goal: The bot is designed to identify the optimal market entry points, achieving a success rate above 70%.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-item-center order-class">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">4. Profit Distribution
                            </p>
                            <p class="about-p-1">Reserve Fund: 20% of profits are allocated to a reserve fund to cover potential losses.
                            </p>
                            <p class="about-p-1">Token Buyback and Burn: 5% will be used for token buybacks or burns, reducing the SBOT supply and increasing its value.
                            </p>
                            <p class="about-p-1">•Bot Development: 25% is allocated for the continuous maintenance and development of the trading bot.
                            </p>
                            <p class="about-p-1">•Participant Rewards: 50% will be distributed among pool participants, proportionally to their participation.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot4} alt="" />
                        </div>
                    </div>
                </div>
                {/* <div className="row align-item-center">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">5. Authorize USDT spending
                            </p>
                            <p class="about-p-1">Your wallet will prompt you to approve the spending of USDT for the presale contract. Accept and confirm the transaction. Congratulations! You're now part of the presale!


                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot5} alt="" />
                        </div>
                    </div>
                </div> */}
                {/* <div className="row align-item-center order-class">
                    <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">6. Buy the token
                            </p>
                            <p class="about-p-1">The contract will be executed on the official launch day, at which point you will receive your tokens directly in your wallet.
                                <br />
                                To view the tokens in your wallet after the launch, you can manually add them using the token contract address.


                            </p>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="left-about">
                            <img src={sabot7} alt="" />
                        </div>
                    </div>
                </div> */}
                {/* <div class="top-heading text-center">
                    <p class="heading-p">Token Contract Address:</p>
                    <p class="about-p-1">0xE3249Ec8902Db06BB3d75e2E9B3998F7F2A09CC5
                        <br />
                        Go to your wallet, select "Add Token," and paste the contract  <br />
                        address. The token will automatically appear after the launch.
                    </p>

                </div> */}
                <div className="row align-item-center">
                    {/* <div class="col-lg-7 content-center">
                        <div class="right-about">
                            <p class="about-p">Important Notes:
                            </p>
                            <p class="about-p-1">:- Always verify the token contract address and ensure you get it only from our official sources.
                                <p class="about-p-1">:- Beware of scams: Only use our official page to connect and make purchases.
                                </p>
                            </p>
                        </div>
                    </div> */}
                    <div class="col-lg-5">
                        <div class="left-about">
                            {/* <img src={sabot4} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <BackToTop />
        </section>
    )
}

export default Howtostake
